import { FiPlus } from 'react-icons/fi'
import { t } from 'i18next'

import { ButtonNavLink } from '~/components/Buttons'
import { DatepickerHeader } from '~/components/datepicker-header'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { getRetailMediaAccountType } from '~/helpers'

import DashboardRetailMediaCampaigns from '../../components/DashboardRetailMediaCampaigns'
import MainTabs from '../../components/MainTabs'

import * as S from './styles'

const RetailMediaDashboard = () => {
  const isAdvertiser = getRetailMediaAccountType() === 'advertiser'

  return (
    <>
      <PageTitle title="Retail Media - Dashboard" />

      <HeaderPages
        title="Retail Media - Dashboard"
        className="has-datepicker"
        sideRightComponent={
          <S.WrapperHeader>
            {isAdvertiser && (
              <ButtonNavLink
                to="/retail-media/campaign/create"
                text={t('rm:createCampaign')}
                iconLeft={<FiPlus />}
              />
            )}

            <DatepickerHeader />
          </S.WrapperHeader>
        }
      />

      <MainTabs />

      <DashboardRetailMediaCampaigns />
    </>
  )
}

export default RetailMediaDashboard
